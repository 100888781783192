* {
  box-sizing: border-box;
}

html {
  height: 100%;
  padding: 0;
  border: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', serif;
  font-size: 16px;
  scroll-behavior: smooth;

  body {
    height: 100%;
    margin: 0;
    background-color: $cWhite;
    #main {
      height: 100%;
      & .mainHolder {
        height: 100%;
      }
    }
  }

  li {
    font-size: 2rem;
  }

  span {
    &.green {
      color: $VHGreen;
    }

    &.grey {
      color: $VHGrey;
    }

    &.grey-light {
      color: $VHGreyLight04;
    }

    &.orange {
      color: $VHOrange;
    }

    &.red {
      color: $VHRed;
    }

    &.blue {
      color: $VHBlue;
    }

    &.status-green {
      color: $StatusGreen;
    }

    &.status-grey {
      color: $StatusGrey;
    }

    &.status-yellow {
      color: $StatusYellow;
    }

    &.status-orange {
      color: $StatusOrange;
    }

    &.status-red {
      color: $StatusRed;
    }
  }

  // -------------------------------- Divider --------------------------------
  .divider {
    background-color: #fff !important;
    margin: 0 7.3% !important;
    opacity: 0.2;
  }

  // -------------------------------- Orange background cover (ex. login) --------------------------------
  .backgroundCover {
    display: block;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    opacity: 0.5;
    background-color: $VHOrange;
  }

  // -------------------------------- Container Icon inside the colored round (ex. map) --------------------------------
  .container-icon-inside {
    display: inline-flex;

    &.green {
      .icon-holder {
        background-color: $VHGreen;
      }
    }

    &.orange {
      .icon-holder {
        background-color: $VHOrange;
      }
    }

    &.red {
      .icon-holder {
        background-color: $VHRed;
      }
    }

    &.blue {
      .icon-holder {
        background-color: $VHBlue;
      }
    }

    &.status-green {
      .icon-holder {
        background-color: $StatusGreen;
      }
    }

    &.status-yellow {
      .icon-holder {
        background-color: $StatusYellow;
      }
    }

    &.status-orange {
      .icon-holder {
        background-color: $StatusOrange;
      }
    }

    &.status-red {
      .icon-holder {
        background-color: $StatusRed;
      }
    }

    &.status-grey {
      .icon-holder {
        background-color: $StatusGrey;
      }
    }

    .icon-holder {
      background-color: $VHGreyLight;
      width: 20px;
      height: 20px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      border-radius: 50%;
      justify-content: center;

      .icon {
        width: 75%;
        height: 75%;
      }
    }
  }

  // -------------------------------- MaterialUI hidden divider(hide for all cases) --------------------------------
  .css-omotrs {
    display: none;
  }

  // -------------------------------- Button --------------------------------
  .button {
    min-width: 140px;
    line-height: 2.2rem;
    text-align: center;
    font-weight: 500;
    padding: 0 20px;
    text-transform: initial;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    background: $VHBlue;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: center;
    align-items: center;

    &.button-left {
      border-radius: 3px 0 0 3px;
    }

    &.button-right {
      border-radius: 0 3px 3px 0;
    }

    &.button-grey {
      background: $VHGrey;
      opacity: 0.5;
    }

    &.button-orange {
      background: $VHOrange;
    }

    &.active {
      background: $VHBlue;
      opacity: 1;
    }

    &:hover {
      background: darken($VHBlue, 5%);
      opacity: 1;
    }

    &.button-radius {
      border-radius: 32.5px;
    }
  }

  // -------------------------------- Small Icon Button (ex. Open/Close, plus, X...) --------------------------------
  .close-button {
    background-color: $VHOrange;
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    padding: 0;
    border-radius: 50%;
  }

  // -------------------------------- Forms (InputHolder Label+TextField) --------------------------------

  .textInputHolder {
    label {
      color: black;
      line-height: 1.6rem;
      position: initial;
      font-size: 1rem;
      font-weight: 300;
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', serif;
      transform: initial;
    }

    div {
      margin: 0;
    }

    img {
      padding-right: 7px;
      width: 24px;
      height: 24px;
    }
  }

  // -------------------------------- Forms (Placeholder) --------------------------------
  .placeholder {
    display: inline-flex;
    margin: 0;
    color: $VHGreyLight;

    img {
      padding-right: 7px;
    }
  }

  // -------------------------------- Forms (Select calendar popup) --------------------------------
  // S M T W T ...
  .reactDateSelectHeader {
    color: $VHGreyLight;
    cursor: default;
    font-size: 75%;
    font-weight: 500;
    display: inline-block;
    width: calc(100% / 7);
    padding: 10px;
    text-align: center;
  }

  // 1 2 3 4 5 6 ...
  .reactDateSelectItems {
    display: inline-block;
    width: calc(100% / 7);
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $VHGreyLight;
    }
  }

  // -------------------------------- Recharts --------------------------------
  .recharts-wrapper {
    .recharts-tooltip-wrapper {
      .recharts-default-tooltip {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        min-width: 150px;
        padding: 10px;
        font-size: 0.8rem;

        .recharts-tooltip-item {
          color: $VHGrey;
          display: flex;
          flex-flow: column nowrap;

          &:first-child {
            font-weight: 600;
          }

          span {
            line-height: 1.5rem;
          }
        }
      }
    }

    .barChartBrush {
      rect:first-child {
        fill: $VHGreySuperLight;
        stroke: none;
      }

      .recharts-brush-traveller {
        display: none;
      }

      .recharts-brush-slide {
        fill: $VHOrange;
        fill-opacity: 1;
      }

      .recharts-brush-texts {
        display: none;
      }
    }
  }

  // -------------------------------- Dialog --------------------------------
  .dialog {
    ::-webkit-scrollbar {
      width: 20px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px white !important;
      border-radius: 10px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: white !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: white !important;
    }

    .close-dialog {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 4;
    }

    &.long {
      div[role='document'] {
        min-height: 400px;
        min-width: 600px;
        max-height: 60%;
      }
    }

    .DialogTitle {
      padding: 6px;
      text-align: center;
      background: $VHGreySuperLight;

      h1,
      h2,
      p {
        font-size: 0.9rem;
      }

      button {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    .DialogContent {
      padding: 24px;
      overflow-y: visible;
    }

    .DialogActions {
      justify-content: space-between;
      margin: 22px 12px;

      button {
        margin: 0 12px;
        width: 100%;
      }
    }
  }

  // -------------------------------- Table --------------------------------

  .table {
    .stickyHeader {
      tr:first-child {
        th {
          position: sticky;
          top: 0;
          background: #ffffff;
          z-index: 3;
        }
      }
    }

    .activeRow {
      background-color: $VHOrangeLight;
    }

    thead {
      tr {
        th,
        td {
          border-bottom: none !important;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 1px solid $VHGreyLight;
          }
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid $VHGreyLight;
        }

        th,
        td {
          border-bottom: none !important;
        }
      }
    }

    &.width-min-576 {
      min-width: 576px;
      table-layout: fixed;

      thead {
        tr {
          th {
            &:nth-child(1) {
              width: 40%;
            }

            &:nth-child(2) {
              width: 16%;
            }

            &:nth-child(3) {
              width: 18%;
            }

            &:nth-child(4) {
              width: 26%;
            }

            &:nth-child(5) {
              width: 52px;
            }
          }
        }
      }
    }
  }

  // -------------------------------- SearchField --------------------------------
  .searchParent {
    color: black;
    border: 1px solid $VHGreyLight;
    display: inline-flex;
    font-size: 1rem;
    line-height: 1.1875em;
    border-radius: 3px;
    position: relative;
    width: 100%;
    padding: 0 10px;

    .searchField {
      flex-grow: 1;
      font: inherit;
      color: $VHGrey;
      margin: 0;
      border: 0;
      padding: 8px 0 7px;
      display: block;
      box-sizing: border-box;
      vertical-align: middle;
      outline: none;
      background: white;
    }
  }

  // -------------------------------- Paper --------------------------------
  .paper {
    .paperHeading {
      background: $VHGreySuperLight;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 2rem;
      position: relative;

      h2 {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
      }

      .help-bar {
        display: flex;
        position: absolute;
        right: 0;
        justify-content: center;

        .container-icon-inside {
          margin-right: 9px;
          font-size: 0.6rem;
          align-items: center;

          .icon-holder {
            margin-right: 5px;
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }

  // -------------------------------- Search field --------------------------------
  .searchField {
    width: 100%;
  }

  // -------------------------------- Scrollbar --------------------------------
  .scrollbar {
    overflow-x: auto !important;

    & > {
      div {
        z-index: 99999999;

        &:first-child {
          max-width: 100%;
          overflow-y: scroll;
          width: fit-content;
          min-width: 100%;
          padding-right: 10px;
          margin-right: -10px !important;
          margin-bottom: 0 !important;
          border-bottom: 1px solid $VHGreyLight;
        }

        &:nth-child(2),
        &:last-child {
          bottom: 1px !important;
          border-radius: 0 !important;
          background-color: $VHGreyLight;

          & > div {
            background-color: $VHOrange !important;
          }
        }

        &:nth-child(2) {
          height: 10px !important;
          height: 10px !important;
          left: 0 !important;
          z-index: 99999999;
        }

        &:last-child {
          width: 10px !important;
          right: 0 !important;
          top: 40px !important;
        }
      }
    }

    &.scrollbar-y {
      & > {
        div {
          &:nth-child(2) {
            visibility: hidden !important;
          }
        }
      }
    }
  }

  // -------------------------------- Error Message --------------------------------
  .errorMessage {
    color: $VHRed;
  }
}

.map-container-info {
  .mapboxgl-popup-content {
    box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.2);
    padding: 0;
    overflow: hidden;
  }
}

.container-pin {
  height: 18px;
  cursor: pointer;

  .map-container-hover {
    display: none !important;
  }

  &:hover {
    z-index: 5 !important;

    .map-container-hover {
      display: block !important;
    }
  }
}

.show-hover-wrap {
  position: relative;

  & > .show-hover-content {
    display: none !important;
  }

  &:hover {
    z-index: 5 !important;

    & > .show-hover-content {
      display: block !important;
    }
  }
}

.map-search-field {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 75%;
  height: fit-content;
  z-index: 99999;

  & > div {
    height: fit-content;
    padding: 0;
    opacity: 0.95;
    background-color: rgb(249, 249, 249);
  }
  .searchWrap {
    width: 100%;
  }
  .searchParent {
    border: none;

    .search-input {
      background-color: inherit;
    }
  }
}

.map-zoom-ctrl {
  flex-direction: row !important;
  border-radius: 4px;
  overflow: hidden;

  button {
    width: 38px !important;
    height: 38px !important;
    background-repeat: no-repeat;
  }

  button:first-child {
    border-bottom: 0 solid transparent !important;
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
    content: '+';
    background-position: 6px 6px !important;
  }

  button:last-child {
    background-position: 6px -19px !important;
  }
}

.mapboxgl-ctrl-top-right {
  width: 100%;

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    position: relative;
    float: left;
    margin: 20px;

    input {
      height: 40px;
      min-width: 380px;
      font-size: 14px;
      padding: 0 25px 0 15px;
      outline: none;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    }

    .geocoder-pin-right {
      position: absolute;
      right: 5px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .geocoder-icon.geocoder-icon-close {
      border: none;
      position: relative;
      outline: none;
      height: 20px;
      width: 20px;
      box-sizing: border-box;
      cursor: pointer;

      &:before,
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 2px;
        margin: -1px -6px;
        background: rgba(0, 0, 0, 0.7);
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      background: #fff;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      list-style: none;
      padding: 5px 10px 5px 15px;
      margin: 0;

      li {
        font-size: 14px;
        cursor: pointer;
        padding: 5px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        &:first-child {
          border: none;
        }
      }
    }
  }
}

/* date time picker */

.datetime-picker {
  position: relative;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100;

  .calendar {
    .calendar-nav {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
      min-height: 32px;
      padding: 4px;

      button {
        background: none;
        border: 0;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          border-left: 2px solid #000;
          border-bottom: 2px solid #000;
          margin: -5px;
          opacity: 0.5;
          transform: rotate(45deg);
        }

        .fa {
          font-size: 18px;
        }

        &:hover {
          background-color: #f0f0f0;
        }
      }

      .current-date {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 0 8px;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }

        &.disabled {
          cursor: default;
        }
      }

      .next-month {
        &:before {
          transform: rotate(-135deg);
        }
      }
    }

    table {
      display: block;
      margin: 4px;

      th,
      td {
        padding: 0;
      }

      thead {
        display: block;
        margin: 8px 0 3px;

        tr {
          display: flex;
          flex-wrap: wrap;
        }

        th {
          color: #ff9a19;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          text-transform: uppercase;
          font-size: 0.8em;
          font-weight: 400;
        }
      }

      tbody {
        display: block;

        tr {
          display: flex;
          flex-wrap: wrap;

          td {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              background-color: #f0f0f0;
            }

            &.prev,
            &.next,
            &.disabled {
              color: #dedede;

              &:hover {
                color: #c5c5c5;
              }
            }

            &.disabled {
              &:hover {
                color: #dedede;
                background-color: transparent;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }

  .shortcuts-bar {
    border-bottom: 1px solid #efefef;
    padding: 8px;

    .btn {
      border: 0;
      background: none;
      cursor: pointer;
      border-radius: 2px;
      padding: 2px 4px;
      outline: none;

      &:hover {
        background-color: #f0f0f0;
      }

      &:last-child {
        float: right;
      }

      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }
}

.datetime-picker .calendar table tbody tr td.now {
  color: #5cc4ef;
  font-weight: 400;
}

.datetime-picker .calendar table tbody tr td.selected {
  background-color: #5cc4ef;
  color: #fff;
  font-weight: 400;
}

.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: #53c1ee;
  color: #fff;
}

.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px;
}

.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0;
}

.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%;
}

.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px;
}

.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px;
}

.datetime-picker .time {
  border-top: 1px solid #efefef;
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datetime-picker .time .show-time .text {
  line-height: 1;
  font-size: 19px;
  font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1;
}

.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datetime-picker .time .sliders .slider-text {
  display: none;
}

.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: linear-gradient(to right, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}

.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer;
}

.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8;
}

.datetime-picker .time .sliders .slider .handle:focus,
.datetime-picker .time .sliders .slider .handle:active {
  background-color: #5cc4ef;
  border-color: #5cc4ef;
}

.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100;
}

.datetime-trigger {
  position: relative;

  .datetime-picker {
    position: absolute;
    top: 100%;
    right: 0;
  }

  &.top {
    .datetime-picker {
      bottom: 100%;
      top: initial;
    }
  }
}

.datetime-range-trigger {
  position: relative;
}

.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%;
}

.datetime-picker-popup,
.datetime-range-picker-popup {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.MuiInputLabel-root {
  &.MuiInputLabel-shrink {
    z-index: 1;
  }
}

.all-routes-pagination {
  @media only screen and (max-width: 1903px) {
    width: 100%;
  }
}

.pickup-orders-pagination {
  @media only screen and (max-width: 1460px) {
    margin-top: 20px !important;
  }
}

.all-filter-children {
  @media only screen and (max-width: 1460px) {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 20px;
  }
}

.add-container-buttom {
  @media only screen and (max-width: 1279px) {
    margin-bottom: 20px;
  }
}

.ant-picker-time-panel-column {
  .ant-picker-time-panel-cell-inner {
    // fix for style overwrite
    font-size: 14px !important;
  }
}
